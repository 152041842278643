export enum RegistriesIdEnum {
  CentralNIC = 'centralnic',
  SIDN = 'nl',
  Nominet = 'uk',
  EURid = 'eu',
  Verisign = 'verisign',
  IdentityDigital = 'donuts',
  IdentityDigitalNDZ = 'donutsndz',
  RoTLD = 'rotld',
  GoDaddy = 'godaddy',
  ZDNS = 'zdns',
  PIR = 'pir',
  DENIC = 'de',
  Openprovider = 'openprovider'
}

export enum RegistriesNameEnum {
  CentralNIC = 'CentralNic',
  SIDN = 'SIDN',
  Verisign = 'Verisign',
  Nominet = 'Nominet',
  EURid = 'EURid',
  IdentityDigital = 'Identity Digital',
  IdentityDigitalNDZ = 'Identity Digital NDZ',
  RoTLD = 'RoTLD',
  GoDaddy = 'GoDaddy',
  ZDNS = 'ZDNS',
  PIR = 'PIR',
  DENIC = 'DENIC',
  OPENPROVIDER = 'Openprovider'
}

export const RegistryMap = new Map([
  ['CentralNic', 'centralnic'],
  ['Verisign', 'verisign'],
  ['SIDN', 'nl'],
  ['Identity Digital', 'donuts'],
  ['Identity Digital NDZ', 'donutsndz'],
  ['RoTLD', 'rotld'],
  ['Nominet', 'uk'],
  ['GoDaddy', 'godaddy'],
  ['ZDNS', 'zdns'],
  ['EURid', 'eu'],
  ['PIR', 'pir'],
  ['DENIC', 'de'],
  ['Openprovider', 'openprovider']
]);

export const InvertedRegistryMap = new Map([
  ['centralnic', 'CentralNic'],
  ['verisign', 'Verisign'],
  ['nl', 'SIDN'],
  ['donuts', 'Identity Digital'],
  ['donutsndz', 'Identity Digital NDZ'],
  ['rotld', 'RoTLD'],
  ['uk', 'Nominet'],
  ['godaddy', 'GoDaddy'],
  ['zdns', 'ZDNS'],
  ['eu', 'EURid'],
  ['pir', 'PIR'],
  ['de', 'DENIC'],
  ['openprovider', 'Openprovider']
]);
