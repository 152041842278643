// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  SettingOutlined,
  FilterOutlined,
  TeamOutlined,
  PlusOutlined,
  RobotOutlined,
  WarningOutlined,
  UploadOutlined,
  HistoryOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';
import { RegistriesNameEnum } from '../enums/Registries';

const icons = {
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  SettingOutlined,
  FilterOutlined,
  TeamOutlined,
  PlusOutlined,
  RobotOutlined,
  WarningOutlined,
  UploadOutlined,
  HistoryOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: 'other',
  // title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined
    },
    {
      id: 'filtered-domains',
      title: <FormattedMessage id="filtered-domains" defaultMessage="Filtered Domains" />,
      type: 'collapse',
      url: '/filtered-domains',
      icon: icons.FilterOutlined,
      children: [
        {
          id: 'cnic',
          title: <FormattedMessage id="cnic" defaultMessage={RegistriesNameEnum.CentralNIC} />,
          type: 'item',
          url: '/filtered-domains/cnic',
          icon: icons.RobotOutlined
        },
        {
          id: 'verisign',
          title: <FormattedMessage id="verisign" defaultMessage={RegistriesNameEnum.Verisign} />,
          type: 'item',
          url: '/filtered-domains/verisign',
          icon: icons.RobotOutlined
        },
        {
          id: 'sidn',
          title: <FormattedMessage id="sidn" defaultMessage={RegistriesNameEnum.SIDN} />,
          type: 'item',
          url: '/filtered-domains/sidn',
          icon: icons.RobotOutlined
        },
        {
          id: 'identity-digital',
          title: <FormattedMessage id="identity-digital" defaultMessage={RegistriesNameEnum.IdentityDigital} />,
          type: 'item',
          url: '/filtered-domains/identity-digital',
          icon: icons.RobotOutlined
        },
        {
          id: 'identity-digitalNDZ',
          title: <FormattedMessage id="identity-digitalNDZ" defaultMessage={RegistriesNameEnum.IdentityDigitalNDZ} />,
          type: 'item',
          url: '/filtered-domains/identity-digitalNDZ',
          icon: icons.RobotOutlined
        },
        {
          id: 'rotld',
          title: <FormattedMessage id="rotld" defaultMessage={RegistriesNameEnum.RoTLD} />,
          type: 'item',
          url: '/filtered-domains/rotld',
          icon: icons.RobotOutlined
        },
        {
          id: 'nominet',
          title: <FormattedMessage id="nominet" defaultMessage={RegistriesNameEnum.Nominet} />,
          type: 'item',
          url: '/filtered-domains/nominet',
          icon: icons.RobotOutlined
        },
        {
          id: 'godaddy',
          title: <FormattedMessage id="godaddy" defaultMessage={RegistriesNameEnum.GoDaddy} />,
          type: 'item',
          url: '/filtered-domains/godaddy',
          icon: icons.RobotOutlined
        },
        {
          id: 'zdns',
          title: <FormattedMessage id="zdns" defaultMessage={RegistriesNameEnum.ZDNS} />,
          type: 'item',
          url: '/filtered-domains/zdns',
          icon: icons.RobotOutlined
        },
        {
          id: 'eu',
          title: <FormattedMessage id="eu" defaultMessage={RegistriesNameEnum.EURid} />,
          type: 'item',
          url: '/filtered-domains/eu',
          icon: icons.RobotOutlined
        },
        {
          id: 'pir',
          title: <FormattedMessage id="pir" defaultMessage={RegistriesNameEnum.PIR} />,
          type: 'item',
          url: '/filtered-domains/pir',
          icon: icons.RobotOutlined
        },
        {
          id: 'de',
          title: <FormattedMessage id="de" defaultMessage={RegistriesNameEnum.DENIC} />,
          type: 'item',
          url: '/filtered-domains/de',
          icon: icons.RobotOutlined
        },
        {
          id: 'openprovider',
          title: <FormattedMessage id="openprovider" defaultMessage={RegistriesNameEnum.OPENPROVIDER} />,
          type: 'item',
          url: '/filtered-domains/openprovider',
          icon: icons.RobotOutlined
        }
      ]
    },
    // {
    //   id: 'tld-settings',
    //   title: <FormattedMessage id="tld-settings" defaultMessage="TLD Settings" />,
    //   type: 'item',
    //   url: '/tld-settings',
    //   icon: icons.SettingOutlined
    // },
    {
      id: 'users',
      title: <FormattedMessage id="users" defaultMessage="Users" />,
      type: 'item',
      url: '/users',
      icon: icons.TeamOutlined
    },
    {
      id: 'errorlog',
      title: <FormattedMessage id="errorlog" defaultMessage="Error Logs" />,
      type: 'item',
      url: '/errorlog',
      icon: icons.WarningOutlined
    },
    {
      id: 'uploadDroplist',
      title: <FormattedMessage id="uploadFile" defaultMessage="Upload Droplist" />,
      type: 'item',
      url: '/uploadDroplist',
      icon: icons.UploadOutlined
    },
    {
      id: 'add-domains',
      title: <FormattedMessage id="add-domains" defaultMessage="Add Domains" />,
      type: 'collapse',
      url: '/add-domains/verisign',
      icon: icons.PlusOutlined,
      children: [
        {
          id: 'add-verisign',
          title: <FormattedMessage id="add-verisign" defaultMessage={RegistriesNameEnum.Verisign} />,
          type: 'item',
          url: '/add-domains/verisign',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-sidn',
          title: <FormattedMessage id="add-sidn" defaultMessage={RegistriesNameEnum.SIDN} />,
          type: 'item',
          url: '/add-domains/sidn',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-nominet',
          title: <FormattedMessage id="add-nominet" defaultMessage={RegistriesNameEnum.Nominet} />,
          type: 'item',
          url: '/add-domains/nominet',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-rotld',
          title: <FormattedMessage id="add-rotld" defaultMessage={RegistriesNameEnum.RoTLD} />,
          type: 'item',
          url: '/add-domains/rotld',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-identity-digitalNDZ',
          title: <FormattedMessage id="add-identity-digitalNDZ" defaultMessage={RegistriesNameEnum.IdentityDigitalNDZ} />,
          type: 'item',
          url: '/add-domains/identityDigitalNDZ',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-zdns',
          title: <FormattedMessage id="add-zdns" defaultMessage={RegistriesNameEnum.ZDNS} />,
          type: 'item',
          url: '/add-domains/zdns',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-eu',
          title: <FormattedMessage id="add-eu" defaultMessage={RegistriesNameEnum.EURid} />,
          type: 'item',
          url: '/add-domains/eu',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-pir',
          title: <FormattedMessage id="add-pir" defaultMessage={RegistriesNameEnum.PIR} />,
          type: 'item',
          url: '/add-domains/pir',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-de',
          title: <FormattedMessage id="add-de" defaultMessage={RegistriesNameEnum.DENIC} />,
          type: 'item',
          url: '/add-domains/de',
          icon: icons.RobotOutlined
        },
        {
          id: 'add-centralnic',
          title: <FormattedMessage id="add-centralnic" defaultMessage={RegistriesNameEnum.CentralNIC} />,
          type: 'item',
          url: '/add-domains/centralnic',
          icon: icons.RobotOutlined
        }
      ]
    },
    {
      id: 'submissionHistory',
      title: <FormattedMessage id="submissionHistory" defaultMessage="Submisssion History" />,
      type: 'item',
      url: '/submissionHistory',
      icon: icons.HistoryOutlined
    }
  ]
};

export default other;
