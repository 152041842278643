import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const Openprovider = () => {
  const visibleColumns: string[] = ['name', 'dropTime', 'droppedAt', 'status', 'registeredTo', 'success-inform', 'ab-inform'];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.Openprovider} />;
};

export default Openprovider;
