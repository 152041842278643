import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import { Box, Grid, Typography } from '@mui/material';
import { DateEnum } from '../../enums/Common';

type RangePickerProps = {
  startDate: number;
  endDate: number;
  handleOnDateChange: (value: [number | null, number | null]) => void;
  isStartEndDateLimitApplied?: boolean;
};

const RangePicker = (props: RangePickerProps) => {
  const { handleOnDateChange } = props;

  const handleStartDateChange = (date: any) => {
    if (date) {
      let startDate = date.set({ hour: 0, minute: 0, second: 0, milliSecond: 0 }).toMillis();
      let newEndDate = date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis();
      let endDate: number;
      if (props.isStartEndDateLimitApplied === false) {
        endDate = newEndDate > props.endDate ? newEndDate + 2678400000 : props.endDate;
      } else {
        endDate = newEndDate + 2678400000 < props.endDate || newEndDate > props.endDate ? newEndDate + 2678400000 : props.endDate;
      }
      handleOnDateChange([startDate, endDate]);
    }
  };

  const handleEndDateChange = (date: any) => {
    if (date) {
      let endDate = date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis();
      let newStartDate = date.set({ hour: 0, minute: 0, second: 0, milliSecond: 0 }).toMillis();
      let startDate: number;
      if (props.isStartEndDateLimitApplied === false) {
        startDate = newStartDate < props.startDate ? newStartDate - 2678400000 : props.startDate;
      } else {
        startDate =
          newStartDate - 2678400000 > props.startDate || newStartDate < props.startDate ? newStartDate - 2678400000 : props.startDate;
      }
      handleOnDateChange([startDate, endDate]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box>
        <Grid container direction="row">
          <Grid item xs={5}>
            <DesktopDatePicker
              label="Start date"
              value={DateTime.fromMillis(props.startDate)}
              onChange={handleStartDateChange}
              inputFormat={DateEnum.DateFormat}
              renderInput={(params: any) => <TextField {...params} sx={{ width: '100%' }} />}
            />
          </Grid>
          <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'} xs={2}>
            <Typography>to</Typography>
          </Grid>
          <Grid item xs={5}>
            <DesktopDatePicker
              label="End date"
              value={DateTime.fromMillis(props.endDate)}
              onChange={handleEndDateChange}
              inputFormat={DateEnum.DateFormat}
              renderInput={(params: any) => <TextField {...params} sx={{ width: '100%' }} />}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default RangePicker;
